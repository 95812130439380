import React, { useState, useEffect } from "react";
import "./PhotoGallery.css";
import photos from "./photos.json";
import { Link } from "react-router-dom";

const PhotoGallery = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [photoText, setPhotoText] = useState("");

  // Get unique years for the dropdown and sort them in reverse chronological order
  const years = Array.from(new Set(photos.map((photo) => photo.year))).sort((a, b) => b - a);

  // Filter photos based on the selected year
  const filteredPhotos = selectedYear
    ? photos.filter((photo) => photo.year.toString() === selectedYear)
    : photos;

  // Group photos by year first, then by event
  const groupedPhotos = filteredPhotos.reduce((acc, photo) => {
    acc[photo.year] = acc[photo.year] || {};
    acc[photo.year][photo.event] = acc[photo.year][photo.event] || [];
    acc[photo.year][photo.event].push(photo);
    return acc;
  }, {});

  // Fetch the text content for the selected photo
  useEffect(() => {
    if (selectedPhoto?.text) {
      fetch(selectedPhoto.text)
        .then((res) => res.text())
        .then((text) => setPhotoText(text))
        .catch(() => setPhotoText("Text content could not be loaded."));
    } else {
      setPhotoText("");
    }
  }, [selectedPhoto]);

  return (
    <div className="container">
      <h1>Photo Gallery</h1>
      <p>
        Below are some photos from past events. If you have any photos to include, please send
        them to <Link to='/contact-webmeister'>Webmeister</Link>.
      </p>
      <br />

      {/* Dropdown for year selection */}
      <div className="photo-year-dropdown-container">
        <label htmlFor="year-select">Select Year:</label>
        <select
          id="year-select"
          className="photo-year-dropdown"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        >
          <option value="">All Years</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      {/* Grouped Photos */}
      <div>
        {Object.entries(groupedPhotos)
          .sort(([yearA], [yearB]) => yearB - yearA) // Sort by year in descending order
          .map(([year, eventsByYear]) => (
            <div key={year}>
              <h2>{year}</h2>
              {Object.entries(eventsByYear).map(([event, photos]) => (
                <div key={event} className="photo-group">
                  <h3>{event}</h3>
                  <div className="photo-grid">
                    {photos.map((photo, index) => (
                      <img
                        key={index}
                        src={photo.small}
                        alt="Thumbnail"
                        className="photo-thumbnail"
                        onClick={() => setSelectedPhoto(photo)}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>

      {/* Modal for displaying the big photo */}
      {selectedPhoto && (
        <div className="modal" onClick={() => setSelectedPhoto(null)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedPhoto.big} alt="Large View" />
            <p>{photoText}</p>
            <button
              className="modal-close-button"
              onClick={() => setSelectedPhoto(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;

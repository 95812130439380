import React, { useState } from "react";
import axios from "axios";
import "./UploadPhotos.css";

const UploadPhotos = () => {
  const [year, setYear] = useState("");
  const [event, setEvent] = useState("");
  const [bigFile, setBigFile] = useState(null);
  const [smallFile, setSmallFile] = useState(null);
  const [textDescription, setTextDescription] = useState("");

  const handleFileUpload = async () => {
    if (!year || !event || !bigFile || !smallFile) {
      alert("Please provide all required fields (Year, Event, and Files). Text is optional.");
      return;
    }

    const formData = new FormData();
    formData.append("year", year);
    formData.append("event", event);
    formData.append("bigFile", bigFile);
    formData.append("smallFile", smallFile);
    formData.append("textDescription", textDescription);

    try {
      const response = await axios.post("/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Files and description uploaded successfully!");
      console.log(response.data);
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Failed to upload files. Please try again.");
    }
  };

  return (
    <div className="upload-photos-container">
      <div className="upload-form">
        <h1 className="form-title">Upload Photos and Description</h1>
        <div className="form-group">
          <label>Year</label>
          <input
            type="text"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            placeholder="Enter Year"
          />
        </div>
        <div className="form-group">
          <label>Event</label>
          <input
            type="text"
            value={event}
            onChange={(e) => setEvent(e.target.value)}
            placeholder="Enter Event"
          />
        </div>
        <div className="form-group">
          <label>Big File</label>
          <input
            type="file"
            onChange={(e) => setBigFile(e.target.files[0])}
          />
        </div>
        <div className="form-group">
          <label>Small File</label>
          <input
            type="file"
            onChange={(e) => setSmallFile(e.target.files[0])}
          />
        </div>
        <div className="form-group">
          <label>Text Description (Optional)</label>
          <textarea
            value={textDescription}
            onChange={(e) => setTextDescription(e.target.value)}
            placeholder="Enter Text Description (Optional)"
          ></textarea>
        </div>
        <button
          onClick={handleFileUpload}
          className="upload-button"
        >
          Upload
        </button>
      </div>
    </div>
  );
};

export default UploadPhotos;
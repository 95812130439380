import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Navbar.css';

const Navbar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleMouseEnter = (index) => {
    setOpenDropdown(index);
  };

  const handleMouseLeave = () => {
    setOpenDropdown(null);
  };

  const navItems = [
    {
      name: "Club Information",
      path: "/club-info",
      dropdown: [
        { name: "About Us", path: "/club-info/about" },
        { name: "Officers", path: "/club-info/officers" },
        { name: "BJCP Education", path: "/club-info/bjcpeducation" },
        { name: "Monthly Styles", path: "/club-info/styles" },
        { name: "Join or Renew", path: "/club-info/join" },
      ],
    },
    {
      name: "Competitions",
      path: "/competitions",
      dropdown: [
        { name: "Happy Holidays Homebrew Competition", path: "/competitions/hhhc" },
        { name: "Mead Me in St. Louis", path: "/competitions/meadme" },
        { name: "Microfest", path: "/competitions/microfest" },
        { name: "VirtuAle", path: "/competitions/virtuale" },
        { name: "Brew In The Lou", path: "/competitions/bil" },
        { name: "MWHBOY Circuit", path: "http://midwesthomebrewer.com/" },
        { name: "Master Homebrewer Circuit", path: "https://www.masterhomebrewerprogram.com/home" },
        { name: "Other BJCP/AHA Competitions", path: "https://www.bjcp.org/competitions/competition-calendar/" },
      ],
    },
    {
      name: "Resources",
      path: "/resources",
      dropdown: [
        { name: "Brew Notes", path: "/resources/brewnotes" },
        { name: "Bylaws", path: "/resources/bylaws" },
        { name: "Presentations", path: "/resources/presentations" },
        { name: "Photo Gallery", path: "/resources/photogallery" },
        { name: "Supporters", path: "/resources/supporters" },
      ],
    },
    { name: "Calendar", path: "/calendar" },
    { name: "Contact Us", path: "/contact" },
  ];

  return (
    <>
    <nav className="navbar">
      <ul className="nav-list">
        {navItems.map((item, index) => (
          <li
            key={index}
            className="nav-item"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <Link to={item.path}>{item.name}</Link>
            {item.dropdown && openDropdown === index && (
              <ul className="dropdown">
                {item.dropdown.map((subItem, subIndex) => (
                  <li key={subIndex} className="dropdown-item">
                    {item.path.startsWith('http') ? (
                      <a href={subItem.path} target="_blank" rel="noopener noreferrer">
                        {subItem.name}
                      </a>
                    ) : (
                      <Link to={subItem.path}>{subItem.name}</Link>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
    </>
  );
};

export default Navbar;

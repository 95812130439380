import Admin from '../../components/Admin.js';

function AdminPage() {
    return (
        <>
            <div >
                <div className="master-table">
                    <div className="home-table">
                        <div className="home-row">
                            <Admin />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminPage;
import React, { useState } from "react";
import UploadPhotos from "./UploadPhotos/UploadPhotos";

const AdminPage = () => {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const correctPassword = "Maplewood";

  const handleLogin = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  if (isAuthenticated) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="w-96 p-4 bg-white rounded-lg shadow-lg">
            <div className="mb-4">
                <p>Welcome to the admin page. Here you can manage the system.</p>
                <UploadPhotos />
            </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="w-96 p-4 bg-white rounded-lg shadow-lg">
        <div className="mb-4">
            <h1 className="text-xl font-bold text-center">Admin Login</h1>
        </div>
        <input
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-4"
        />
        <button
            onClick={handleLogin}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded"
        >
            Login
        </button>
        </div>
    </div>
  );
};

export default AdminPage;
import React from 'react';
import './Officers.css'; // Import the CSS file

const Officers = () => {
  return (
    <>
        <div className="master-table">
                <div className="master-row">
                    <div className="master-column master-section">
                    <h1>Officers</h1>
                    <p>
                        The club has five officers that lead and represent the club.  These officers are elected at our January 
                        meeting and have a term of one year, with a maximum of two consecutive terms. Below are our officers
                        for this year.
                    </p>
                    <div className="officer-group">
                        <h2 className="officer-title">President - Cory Kaufman</h2>
                        <table>
                            <tr>
                                <td>
                                    <div>
                                        <a href="https://www.instagram.com/stlbrews">
                                            <img  src="/assets/Cory Pic.jpg" alt="president"  className="officer-img"/>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div className="officer-text">
                                        <p>
                                            Growing up, Cory was always interest in cooking and in creating fun food flavor 
                                            combinations. He even considered a career along the culinary paths, but pursued 
                                            engineering instead.  After turning 21, his interests turned to alcoholic 
                                            beverages, and he received his first brewing kit for his 22nd birthday.
                                        </p>
                                        <p>
                                            Since that time, he has brewed, participated in and led several
                                            homebrew organizations, became a BJCP beer judge, and passed
                                            the cicerone exam.  Cory is a National level BJCP judge, and 
                                            has certifications in both mead and cider. 
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className="officer-group">
                        <h2 className="officer-title">Vice President - Kent Robertson</h2>
                        <table>
                            <tr>
                                <td>
                                    <div>
                                        <a href="https://www.instagram.com/stlbrews">
                                            <img  src="/assets/Kent Pic.jpg" alt="president"  className="officer-img"/>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div className="officer-text">
                                        <p>
                                            Kent has been homebrewing for more than 20 years, and has
                                            been an active Brews member for most of that time. His love 
                                            of homebrewing has led Kent to hold several positions over
                                            the years as an officer of the club, and he is also the owner
                                            of Missouri Malt.
                                        </p>
                                        <p>
                                            He is expecially interested in lager brewing, especially
                                            German lagers, and is currently working on perfecting
                                            decoction mashing.  Kent's favorite beer tasting experience
                                            is drinking lager <i>vom fass</i>
                                            in a biergarten filled with people and live music.
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className="officer-group">
                        <h2 className="officer-title">Treasurer - Mike Green</h2>
                        <table>
                            <tr>
                                <td>
                                    <div>
                                        <a href="https://www.instagram.com/stlbrews">
                                            <img  src="/assets/Michael Pic.jpg" alt="president"  className="officer-img"/>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div className="officer-text">
                                        <p>
                                            Mike began drinking good beer after college through friends, a beer of the month subscription, and going 
                                            to the Central West End Itap (Rest In Peace).
                                        </p>
                                        <p>
                                            Mike’s first foray into homebrewing came when he needed a day off and something to do during COVID.  He purchased a 
                                            couple of Northern Brewer 1 gallon kits and quickly realized brewing on the stove was not ideal. He then moved to 
                                            typical 5 gallon all-grain batches.
                                        </p>
                                        <p>
                                            Mike joined the St. Louis Brews in 2022 so that he would no longer be a lone wolf homebrewer and have a group of 
                                            people to bounce ideas off of and share beer. He is a national BJCP beer judge, cider and mead judge, and a 
                                            Certified Cicerone®.
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className="officer-group">
                        <h2 className="officer-title">Secretary - Matt Schaller</h2>
                        <table>
                            <tr>
                                <td>
                                    <div>
                                        <a href="https://www.instagram.com/stlbrews">
                                            <img  src="/assets/photo-coming-soon.jpg" alt="president"  className="officer-img"/>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div className="officer-text">
                                        <p>
                                            Matt's first foray into homebrewing came when he bought his 
                                            friend a homebrew kit for his wedding.  While "helping" his 
                                            friend, Matt quickly realized that he loved the creative outlet 
                                            that homebrewing allowed.  There was just one problem - the 
                                            first several batches of beer were not very good.  
                                            So Matt decided to join the St. Louis Brews in 2010 to learn 
                                            about how other brewers were crafting their recipes.  
                                        </p>
                                        <p>
                                            Through 
                                            friendship brews, tasting good beer, and being introduced to 
                                            educational books and judging competitions, Matt has refined 
                                            his craft and is now a "decent" brewer. In fact, as part of his 
                                            team winning the competition associated with the club's 30th
                                            anniversary, he got to brew a Saison at Schlafly.
                                        </p>
                                        <p>
                                            As secretary, Matt is happy to pay it forward and hopes 
                                            to not only continue perfecting his own craft, but help 
                                            grow St. Louis's already stellar beer scene.
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className="officer-group">
                        <h2 className="officer-title">Brewmeister - Mark Kunzelman</h2>
                        <table>
                            <tr>
                                <td>
                                    <div>
                                        <a href="https://www.instagram.com/stlbrews">
                                            <img  src="/assets/Mark Pic.jpg" alt="president"  className="officer-img"/>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div className="officer-text">
                                        <p>
                                            Mark developed a taste for good beer in college and started brewing his own in 1993. These were 
                                            5-gallon extract batches brewed on a stovetop. Before long, he was incorporating ever-increasing 
                                            amounts of base and specialty malts to improve the quality and flavor of his beers. 
                                        </p>
                                        <p>
                                            In 1998, while living in Portland, Oregon, he made the jump to all-grain brewing and never looked back. He 
                                            still uses a stovetop for the occasional decoction mash but otherwise, he operates an electric HERMS brewery 
                                            in his basement. 
                                        </p>
                                        <p>
                                            Mark is a BJCP beer, mead and cider judge, and he regularly competes in local and national brewing competitions.
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
  
    </>
  );
};

export default Officers;
